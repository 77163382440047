import React from 'react';
import SEO from '../components/SEO';
import Layout from '../components/Layout';
import Groups from '../components/Groups';

export default () => (
  <React.Fragment>
    <SEO
      title="Groups | Mindset Family Therapy"
      description="Information about Mindset Family Therapy's Scrupulosity OCD Education & Support Group."
      pathname="/groups"
    />
    <Layout>
      <Groups />
    </Layout>
  </React.Fragment>
);
