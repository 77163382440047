import _ from 'lodash';
import React from 'react';
import { Link } from 'gatsby';
import PageHeader from '../PageHeader';
import BookTeaser from '../BookTeaser';

import styles from './groups.module.scss';

const Groups = () => {
  const background =
    'https://res.cloudinary.com/dewd4pral/image/upload/q_60/v1497108735/therapistFullBackgroung_lapqmb.jpg';
  // const ocdGroupImage =
  //   'https://res.cloudinary.com/dewd4pral/image/upload/q_60/v1526346749/ocdGroupImg_jwbrfy.jpg';

  return (
    <main>
      <PageHeader pageCategory="About" pageName="Groups" headerImage={background} />

      <div className={styles.bookWrapper}>
        <BookTeaser bookName="Imperfectly Good" />
      </div>

      <div className={styles.contentWrapper}>
        <div className={styles.groupContainer}>
          <div className={styles.textContainer}>
            <h2 className={styles.header}>Online LDS Faith and OCD Support Group</h2>

            <div style={{ marginTop: -60 }} className={styles.textContainer}>
              <p className={styles.copy}>
                <strong>When:</strong> First Tuesday of every month from 6 to 7:30pm
              </p>
              <p className={styles.copy}>
                <strong>Where:</strong> Zoom
              </p>
              <p className={styles.copy}>
                <strong>Who:</strong> Adults who struggle with OCD and are members of The Church of
                Jesus Christ of Latter-Day Saints
              </p>
              <p className={styles.copy}>
                <strong>Fee:</strong> Free
              </p>

              <p className={styles.copy}>
                <br />
                This support group will provide opportunities for you to share your struggles and
                successes in your OCD journey as a member of the Church. During our time together,
                we will also address faith principles and teachings, and how those can strengthen
                your relationship with God despite OCD (any themes).
                <br />
                <br />
                If you have a loved one who is struggling with OCD and you wish to attend this
                group, you are also welcome! If you wish to attend and/or invite a friend or
                relative who is LDS and is also suffering with OCD, please{' '}
                <Link to="/contact"> contact us</Link> so we can add you or them to the group email
                list. We will send a Zoom link the day before each meeting.
                <br />
                <br />
                Participants don’t need to be current clients at Mindset Family Therapy.
                <br />
                <br />
                Your faith and spirituality can be affected by OCD, and they are not the problem.
                OCD is a mental health condition, and how you respond to it can be the challenge.
                There are evidence-based treatment modalities that can help you start living with
                vitality and meaning.
              </p>

              <h2 style={{ marginBottom: -60, textAlign: 'center' }} className={styles.headerSmall}>
                <br />
                You are not alone and there is always hope!
              </h2>
            </div>
          </div>
        </div>

        <div className={styles.groupContainer}>
          <div className={styles.textContainer}>
            <h2 className={styles.header}>Online Scrupulosity OCD Education & Support Group</h2>
            <div style={{ marginTop: -60, paddingBottom: 20 }} className={styles.textContainer}>
              <p className={styles.copy}>
                <strong>Date:</strong> Every 2nd and 4th TUESDAY of each month
              </p>
              <p className={styles.copy}>
                <strong>Time:</strong> 6:00 - 7:30pm
              </p>
              <p className={styles.copy}>
                <strong>Where:</strong> Zoom – An Online HIPAA compliant telehealth platform
              </p>
            </div>
            <p className={styles.copy}>
              Do you think you might have OCD, and does it seem to target your religion or moral
              values? Do you constantly revisit episodes in your past and worry you&apos;ve done
              something wrong? Do you feel the need to confess repeatedly and often? Feel ashamed,
              guilty, or burdened by &quot;bad thoughts&quot; or worry you might be a bad person? If
              this sounds familiar, then this group is for you.
              <br />
              <br />
              Everyone is welcome, and all types of OCD are addressed. But &quot;scrupulosity&quot;
              OCD is a common focus, with religion freely discussed. What is scrupulosity? Learn{' '}
              <Link to="/specialties/scrupulosity-ocd">more here</Link>. In this group, you will
              learn that you&apos;re not alone in your struggles and will learn new tools to better
              handle them. This group, which is free to those struggling with scrupulosity OCD, is
              currently being offered <strong>online.</strong> Please{' '}
              <Link to="/contact">contact us</Link> so we can send you the Zoom link.
            </p>
          </div>
        </div>
      </div>
    </main>
  );
};

export default Groups;
